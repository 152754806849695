/**
 * Construct image url using external image processing provider
 * @param src image source(main url)
 * @returns new image url using image processing CDN 
 * to generate image with different size and format
 */
type ImageFormat = "webp" | "png" | "jpg";
type GetImageLinkConfigTypes = {
  formats: ImageFormat;
};
export function getImageLink(src: string, imageConfig?: GetImageLinkConfigTypes): string {
  const format = imageConfig?.formats ?? "webp"
  return (
    process.env.NODE_ENV == "production"
      ? `https://images.weserv.nl/?url=${src}&output=${format}&q=85`
      : src
  );
}

/**
 * Retrive file extension
 * 
 * @param name file name or url
 * @returns extension of the file
 */
export function getExtension(name: string) {
  const lastIndex = name.lastIndexOf(".");
  const extension = name.substring(lastIndex + 1);

  return extension;
}