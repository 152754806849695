import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react'
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { LineItemType } from '../../components/templates/product/types';
import { getExtension, getImageLink } from '../../helpers/images';
import CartStorage from '../../helpers/storage';
import { useCart } from '../../hooks/useCart';
import useFreshShipping from '../../hooks/useShipping';
import { NodeEdges, ProductType, ShippingZoneType } from '../../types/global';
import CartPageStaticQueryType from '../../types/pages/cart';

export default function Cart() {
  const [state, dispatch] = useCart();
  const {
    site: {
      buildTime
    },
    storeShipping,
    storeDetails: { store_shipping }
  } = useStaticQuery<CartPageStaticQueryType>(graphql`
    query CartPage {
      site {
        buildTime(formatString: "YYYY-MM-DD")
      }
      storeDetails: zzStoreWebsiteDetails {
        store_shipping
      }
      storeShipping: allZzStoreShipping(sort: {fields: [zzenz_id], order: ASC}) {
        edges {
          node {
            zzenz_id
            name
            cost
            location {
              code
              type
              name
            }
          }
        }
      }
    }
  `);
  const [shippingState] = useFreshShipping(store_shipping, storeShipping.edges);
  const buildDates = {
    first_publication_date: buildTime,
    last_publication_date: buildTime,
  };
  const getLineItemsTotal = () => {
    const totalPrice = state.data?.lineItems?.reduce((acc: number, { product_id, quantity }: LineItemType) => {
      const { regular_price } = state.data.products[product_id];
      return acc + (regular_price * quantity);
    }, 0);

    return totalPrice;
  }
  const onRemove = (itemIndex: number) => async () => {
    dispatch({
      type: "LOADING",
      payload: true
    });

    const cart = new CartStorage();
    const newLineItems = await cart.removeItemByIndex(itemIndex);

    if (newLineItems !== null) {
      dispatch({
        type: "SET_FIELDS",
        payload: {
          ...state.data,
          lineItems: newLineItems
        }
      });
    }
  }
  const onEditQuantity = (index: number, newQuantity: number) => async () => {
    dispatch({
      type: "LOADING",
      payload: true
    });

    const cart = new CartStorage();
    const newLineItems = await cart.updateItemQuantityByIndex(index, newQuantity);

    dispatch({
      type: "SET_FIELDS",
      payload: {
        ...state.data,
        lineItems: newLineItems
      }
    });
  }
  const getShippingTotal = () => {
    if (state.data.shippingLines.length > 0) {
      return parseFloat(state.data.shippingLines?.[0]?.total) || 0;
    } else {
      return parseFloat(String(storeShipping.edges?.[0]?.node.cost)) || 0;
    }
  }
  const onEditShipping = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    const selectedShippind = shippingState.data.find(({ node: { location } }: NodeEdges<ShippingZoneType>) => location.code === value);

    if (selectedShippind !== undefined) {
      const cart = new CartStorage();
      const shippingLine = {
        method_id: "flat_rate",
        id: selectedShippind?.node?.zzenz_id,
        method_title: selectedShippind?.node.name,
        total: selectedShippind?.node.cost
      };
      dispatch({
        type: "SET_FIELD",
        payload: {
          key: "shippingLines",
          value: [shippingLine]
        }
      });
      await cart.updateShippingLine(shippingLine);
    }
  }
  const getShippingLocation = () => {
    const selectedShippind = storeShipping.edges.find(({ node: { zzenz_id } }) => {
      return zzenz_id === state.data.shippingLines?.[0]?.id;
    });
    return selectedShippind?.node?.location?.code;
  }

  return (
    <Layout>
      <SEO
        title={"Panier"}
        pathname={"/cart/"}
        description={""}
        banner={""}
        node={buildDates}
      />
      <div className={"container"}>
        <section className="py-5 bg-light">
          <div className="container">
            <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
              <div className="col-lg-6">
                <h1 className="h2 text-uppercase mb-0">Panier</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-lg-end mb-0 px-0 bg-light">
                    <li className="breadcrumb-item">
                      <Link
                        to={"/"}
                        className="text-dark"
                      >
                        Accueil
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Panier</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <h2 className="h5 text-uppercase mb-4">Panier</h2>
          <div className="row">
            <div className="col-lg-8 mb-4 mb-lg-0">
              <div className="table-responsive mb-4">
                <table className="table text-nowrap">
                  <thead className="bg-light">
                    <tr>
                      <th className="border-0 p-3" scope="col"> <strong className="text-sm text-uppercase">Produit</strong></th>
                      <th className="border-0 p-3" scope="col"> <strong className="text-sm text-uppercase">Prix</strong></th>
                      <th className="border-0 p-3" scope="col"> <strong className="text-sm text-uppercase">Quantité</strong></th>
                      <th className="border-0 p-3" scope="col"> <strong className="text-sm text-uppercase">Total</strong></th>
                      <th className="border-0 p-3" scope="col"> <strong className="text-sm text-uppercase"></strong></th>
                    </tr>
                  </thead>
                  <tbody className="border-0">
                    {
                      state.data?.lineItems?.map(({ quantity, product_id }: LineItemType, index: number) => {
                        const product: Required<ProductType> = state.data.products[product_id];
                        const productImage = getImageLink(product?.thumbnail?.src);
                        const images = {
                          sources: [
                            {
                              media: "",
                              type: "image/webp",
                              srcSet: productImage,
                            },
                            {
                              media: "",
                              type: `image/${getExtension(product?.thumbnail?.src)}`,
                              srcSet: productImage,
                            }
                          ]
                        };
                        const image: IGatsbyImageData = {
                          height: product?.thumbnail?.height * 70 / product?.thumbnail?.width,
                          width: 70,
                          backgroundColor: "#12206755",
                          layout: "fixed",
                          images
                        };

                        return (
                          <tr key={`${product.slug}-index${index}`}>
                            <th className="ps-0 py-3 border-light" scope="row">
                              <div className="d-flex align-items-center">
                                <Link to={`/product/${product.slug}`} className="reset-anchor d-block animsition-link">
                                  <GatsbyImage
                                    image={image}
                                    alt={product.name}
                                  />
                                </Link>
                                <div className="ms-3">
                                  <strong className="h6">
                                    <Link to={`/product/${product.slug}`} className="reset-anchor animsition-link">
                                      {product.name}
                                    </Link>
                                  </strong>
                                </div>
                              </div>
                            </th>
                            <td className="p-3 align-middle border-light">
                              <p className="mb-0 small">{product.regular_price}DZD</p>
                            </td>
                            <td className="p-3 align-middle border-light">
                              <div className="border d-flex align-items-center justify-content-between px-3"><span className="small text-uppercase text-gray headings-font-family">Quantité</span>
                                <div className="quantity">
                                  <button
                                    onClick={onEditQuantity(index, Number(quantity) - 1)}
                                    className="dec-btn p-0"
                                  >
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 192 512" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" />
                                    </svg>
                                  </button>
                                  <input className="form-control form-control-sm border-0 shadow-0 p-0" type="text" value={quantity} readOnly />
                                  <button
                                    onClick={onEditQuantity(index, Number(quantity) + 1)}
                                    className="inc-btn p-0"
                                  >
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 192 512" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td className="p-3 align-middle border-light">
                              <p className="mb-0 small">{product.regular_price * Number(quantity)}DZD</p>
                            </td>
                            <td className="p-3 align-middle border-light">
                              <button
                                className="btn inc-btn p-0"
                                onClick={onRemove(index)}
                              >
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 448 512"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z" />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              <div className="bg-light px-4 py-3">
                <div className="row align-items-center text-center">
                  <div className="col-md-6 mb-3 mb-md-0 text-md-start">
                    <Link
                      className="btn btn-link p-0 text-dark btn-sm"
                      to="/shop/"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        className={"me-2"}
                      >
                        <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
                      </svg>
                      Continuer vos achats
                    </Link>
                  </div>
                  <div className="col-md-6 text-md-end">
                    <Link className="btn btn-outline-dark btn-sm" to="/cart/checkout">
                      Valider votre commande
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        className={"ms-2"}
                      >
                        <path d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card border-0 rounded-0 p-lg-4 bg-light">
                <div className="card-body">
                  <h5 className="text-uppercase mb-4">Montant du panier</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center justify-content-between">
                      <strong className="text-uppercase small font-weight-bold">Sous-total</strong>
                      <span className="text-muted small">{getLineItemsTotal()}DZD</span>
                    </li>
                    <li className="d-flex align-items-center justify-content-between border-bottom my-2">
                      <strong className="text-uppercase small font-weight-bold">Livraison</strong>
                      <div className={"d-flex justify-content-between gap-3"}>
                        <select
                          name={"location"}
                          onChange={onEditShipping}
                          value={getShippingLocation()}
                          disabled={state.data?.lineItems.length === 0}
                          className={"form-control"}
                        >
                          {
                            shippingState.data.map(({ node: { cost, location } }: NodeEdges<ShippingZoneType>) => {
                              if (String(cost) === "0") return null;
                              return (
                                <option
                                  value={location.code}
                                  key={location.code}>
                                  {location.name}
                                </option>
                              );
                            })
                          }
                        </select>
                        <span className="text-muted small">
                          {getShippingTotal()}DZD
                        </span>
                      </div>
                    </li>
                    <li className="d-flex align-items-center justify-content-between">
                      <strong className="text-uppercase small font-weight-bold">Total</strong>
                      <span>{getLineItemsTotal() + getShippingTotal()}DZD</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </Layout>
  )
}
